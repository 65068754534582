import React from "react";

function Bioblinds() {
  return (
    <div className=" bg-green-default">
      <div className="section-container">
        <div className="flex w-full justify-center">
          <h1 className="heading text-center">Bioblinds 2nd Edition COWSHED</h1>
        </div>
        <div className="bg-white px-6 py-5 md:mt-20 mt-10">
          <p className="para-graph ">
            We are proud to say that we already work on a new edition – for a
            context we had not anticipated until the request from the
            Friedrichshof Gründau to vegetate their cowshed for 800 cows:
            Bioblinds for cows. The prototyping starts this May 2020. You can
            follow our progress by subscribing to the newsletter or participate
            by helping in regard to our challenges. Scroll down to see some
            impressions of our adventure.
          </p>

          <p className=" text-lg text-black-default underline hover:no-underline duration-150 ease-linear transition-all cursor-pointer">
            Subscribe to our monthly-ish newsletter to stay informed and help us
            with the progress in regard to equipping cowsheds with Bioblinds.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Bioblinds;
