import React from "react";
import Bioblinds from "./Bioblinds";
import Gallery from "./Gallery";
import Layout from "./../Layout/Layout";

const Index = () => {
  return (
    <Layout>
      <Bioblinds />
      <Gallery />
    </Layout>
  );
};

export default Index;
